// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { buildFrontUrl } from '../../../routes';

type Props = {
  footer: Object,
  footerContent: Object,
  lang: string,
  country: string,
  partner: string,
  switches: Object,
}

class Footer extends React.Component<Props, State> {
  redirect = (link) => {
    window.location.href = link + window.location.search;
  };

  render() {    
    return (
      <div className="footer">
        <div className="bottom-bar">
          <div className="document-container">
            {
              this.props.footer.map((document, index) => (
                document && document.label && document.url &&
                <a 
                  key={index}
                  href={document.url}
                  rel="noopener noreferrer"                  
                  target="_blank" 
                  className="document"
                  tabIndex="0"
                  role="link" 
                  aria-label={document.label}
                >
                  {document.label}
                </a>
              ))
            }            
          </div>
        </div>
        <div
          className="footer-content rich-text-content base-content"
          dangerouslySetInnerHTML={{ __html: this.props.footerContent }}
        />
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({}),
  {},
)(Footer));
